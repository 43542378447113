<template>
  <div class="login flex">
    <div class="head flex">
      <img src="../../assets/logo.png" width="28px" alt="logo">
      <el-divider direction="vertical"></el-divider>
      <span>商家中台</span>
    </div>
    <div class="contain">
      <div class="top">
        <div class="flex" style="justify-content: space-between;">
          <p class="title">找回密码</p>
          <router-link class="return" :to="{ path: '/login' }">返回登录页</router-link>
        </div>
      </div>
      <div class="step" v-if="!success">
        <p class="step-title">请填写下面的信息</p>
        <div class="step-bd">
          <div class="step-bd-input flex">
            <span class="label">用户名</span>
            <el-input class="input height-fourty" placeholder="请输入用户名" v-model="username"></el-input>
          </div>
          <div class="step-bd-input flex">
            <span class="label">新密码</span>
            <el-input class="input height-fourty" type="password" placeholder="请输入新密码" v-model="password" show-password></el-input>
          </div>
          <div class="step-bd-input flex">
            <span class="label">验证码</span>
            <el-input type="number" class="input height-fourty" placeholder="请输入验证码" v-model="authcode"></el-input>
            <el-button type="primary" class="authcode-btn height-fourty" :disabled="sendAuthCode" @click="getAuthCode">{{sendAuthCode ? `${authTime}s后重发` : '获取验证码'}}</el-button>
          </div>
          <div class="step-bd-input flex-center">
            <el-button type="primary" class="height-fourty" @click="confirm">确定修改</el-button>
          </div>
        </div>
      </div>
      <div class="success flex-center" v-else>
        <img src="../../assets/icon/succeed_big.png" width="72px" alt="success">
        <span class="success-text">重置密码成功</span>
        <span class="success-tips">{{returnTime}}s后返回登录</span>
        <el-button type="primary" class="height-fourty" @click="toLogin">返回登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import $md5 from 'js-md5'

export default {
  name: 'Getpwd',
  data() {
    return {
      authTime: 60,
      returnTime: 3,
      username: '',
      password: '',
      authcode: '',
      authCodeShow: false,
      sendAuthCode: false,
      success: false
    }
  },
  methods: {
    getAuthCode() {
      if (!this.username) {
        this.$message.error('请输入用户名')
        return
      }
      const params = {
        user_name: this.username,
        type: 2
      }
      const url = 'admin/user/verify/send'
      this.$https.get(url, {params}).then(({code, msg}) => {
        if (code === 200) {
          this.sendAuthCode = true
          this.authTime = 60
          let authTimeTimer = setInterval(() => {
            this.authTime--
            if (this.authTime <= 0) {
              this.sendAuthCode = false
              clearInterval(authTimeTimer)
            }
          }, 1000)
        } else {
          this.$message.error(msg)
        }
      })
    },
    confirm() {
      if (!this.username || !this.password || !this.authcode) {
        this.$message.error('请完善输入信息')
        return
      }
      const params = {
        user_name: this.username,
        pwd: $md5(this.password).toUpperCase(),
        code: this.authcode
      }
      const url = 'admin/user/modify/pwd'
      this.$https.post(url, params).then(({code, msg}) => {
        if (code === 200) {
          this.success = true
          let authTimeTimer = setInterval(() => {
            this.returnTime--
            if (this.returnTime <= 0) {
              clearInterval(authTimeTimer)
              this.toLogin()
            }
          }, 1000)
        } else {
          this.$message.error(msg)
        }
      })
    },
    toLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  .head{
    padding: 0 215px;
    height: 60px;
    line-height: 60px;
    width: 100%;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #D8D8D8;
    font-weight: bold;
  }
  .contain {
    width: 666px;
    padding: 0 24px;
    margin: 0 auto;
    box-sizing: border-box;
    .top {
      height: 162px;
      padding-top: 50px;
      border-bottom: 1px solid #E8E8E8;
      box-sizing: border-box;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }
      .return {
        text-decoration: none;
        color: #1890FF;
      }
    }
    .step {
      &-title {
        margin: 0;
        padding-top: 24px;
        color: #000;
        font-weight: 600;
      }
      &-bd {
        display: flex;
        flex-direction: column;
        padding: 0 98px;
        margin-top: 32px;
        &-input {
          align-items: center;
          .label {
            width: 50px;
            padding-right: 12px;
            color: #000;
          }
          .input {
            flex: 1;
          }
          .authcode-btn {
            width: 102px;
            margin-left: 10px;
          }
        }
        .step-bd-input+.step-bd-input {
          margin-top: 24px;
        }
      }
    }
    .success {
      flex-direction: column;
      padding-top: 50px;
      &-text {
        padding-top: 20px;
        font-weight: 600;
        font-size: 24px;
        color: rgba($color: #000000, $alpha: 0.85);
      }
      &-tips {
        padding-top: 8px;
        color: rgba($color: #000000, $alpha: 0.45);
      }
      .height-fourty {
        margin-top: 48px;
      }
    }
  }
}
</style>
